import { graphql } from 'gatsby';
import React from 'react';

import Content from 'components/pages/blog-post/content';
import Hero from 'components/pages/blog-post/hero';
import LatestArticles from 'components/pages/blog-post/latest-articles';
import MainLayout from 'layouts/main';
import { getReactContentWithLazyBlocks } from 'utils/get-react-content-with-lazy-blocks';

const BlogPost = ({
  data: {
    wpPost: { content, seo, title, author, categories, readingTime },
  },
  pageContext,
}) => {
  const { reactedContent } = getReactContentWithLazyBlocks(content);
  return (
    <MainLayout seo={seo} pageContext={pageContext}>
      <Hero
        title={title}
        authorName={author.node.name}
        categories={categories?.nodes}
        readingTime={readingTime}
      />
      <Content content={reactedContent} />
      <LatestArticles />
    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      content
      title
      author {
        node {
          name
        }
      }
      categories {
        nodes {
          acf {
            color
          }
          name
          slug
        }
      }
      readingTime
      url: uri

      ...wpPostSeo
    }
  }
`;

export default BlogPost;
